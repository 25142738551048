import { initPlasmicLoader } from "@plasmicapp/loader-react";
import { ScriptGTM } from './components/ScriptGTM';  // Certifique-se de que o caminho está correto

export const PLASMIC = initPlasmicLoader({
  projects: [
    {
      id: "wg2xKxQcLTFNjdW2qRdMZV",
      token: "uHBtdzbHgvPXEyYDnj6psaKUN5zO7u9r5yf6xYS3DQPToKB6Ab7p2KhGS41RUdz0h7ccprI9Cn5yQRRcFpg"
    }
  ],
  preview: false,
});

PLASMIC.registerComponent(ScriptGTM, {
  name: 'ScriptGTM',
  importPath: './components/ScriptGTM',
  props: {
    gtmId: {
      type: 'string',
      defaultValue: 'GTM-XXXXXXX',
      description: 'O ID do contêiner GTM'
    },
    enabled: {
      type: 'boolean',
      defaultValue: true,
      description: 'Habilitar ou desabilitar o GTM'
    }
  }
});
